import React, { useState, useEffect, Component } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import PatientFilesRight from '../Patients/patientFilesRight';
import MedicalfileView from '../images/media/medicalfileView.jpg';
import Location from '../images/icons/Location.png';
import LocationBlue from '../images/icons/locationBlue.png';
import { Label } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { callingAPI } from '../config';
import PrismaZoom from 'react-prismazoom'
import ReactTooltip from 'react-tooltip';
import $ from "jquery"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PatientFilesMedicalView() {

	var mouseX, mouseY, mouseXw, mouseXh;
	var divMouseX, divMouseY;
	const location = useLocation();

	const [emrPatientdocument_name, setemrPatientdocument_name] = useState('');
	const [emrPatientSpecializationRecordsId, setemrPatientSpecializationRecordsId] = useState('');
	const [emrPatientRecordId, setEmrPatientRecordId] = useState('');
	const [strMouseX, setStrMouseX] = useState('');
	const [strMouseY, setStrMouseY] = useState('');
	const [annotationTitle, setAnnotationTitle] = useState('');
	const [annotationDescription, setAnnotationDescription] = useState('');
	const [showPopup, setShowPopup] = useState(false);
	const [annotatedData, setAnnotatedData] = useState([]);

	const annotationUpdated = (annotationArray = []) => {
		setAnnotatedData(annotationArray);
		//document.getElementById("blueId").attr('src',LocationBlue)
	}
	const openPopupbox = (e) => {
		if (showPopup) {
			return;
		}
		var rect = e.target.getBoundingClientRect();
		mouseX = e.clientX - rect.left; //x position within the element.
		mouseY = e.clientY - rect.top;  //y position within the element.
		mouseXw = rect.width; // width of the element.
		mouseXh = rect.height;  // height of the element.
		var prismaZoomScale = $(".prismaZoom").css('transform');
		var prismaZoomScale = prismaZoomScale.substring(prismaZoomScale.indexOf('(') + 1, prismaZoomScale.indexOf(')')).split(',');

		var xPercent = Math.round(mouseX / mouseXw * 100);
		var YPercent = Math.round(mouseY / mouseXh * 100);

		//  divMouseX = xPercent/prismaZoomScale[0]
		//  divMouseY = YPercent/prismaZoomScale[0]
		divMouseX = xPercent
		divMouseY = YPercent

		setStrMouseX(divMouseX);
		setStrMouseY(divMouseY);

		document.getElementById("annotationArea").style.cssText = "left: " + divMouseX + "%; top: " + divMouseY + "%";
		document.getElementById("annotationAreaLoc").style.cssText = "left: " + divMouseX + "%; top: " + divMouseY + "%";
		if ($(window).width() < 767) {
			if (divMouseX > 45) {
				document.getElementById("annotationArea").style.cssText = "left: " + 35 + "%; top: " + divMouseY + "%";
			}
		}
		setShowPopup(true);

	};
	const onChangeCancel = () => {
		setShowPopup(false);
		setAnnotationTitle('')
		setAnnotationDescription('')
	};

	const onChangeSave = () => {
		saveAnnotationApi();
	}
	const saveFolderAnnotation = () => {
		const postData = {
			recordId: emrPatientRecordId,
			memberId: window.sessionStorage.getItem("memberId"),
			hospitalId: localStorage.getItem("hospitalId"),
			doctorId: window.sessionStorage.getItem('doctorId'),
			scheduledtimeSlotStartTime: "",
			scheduledtimeSlotEndTime: "",
			appointmentDate: "",
			siteId: window.sessionStorage.getItem("siteId"),
			userId: window.sessionStorage.getItem("userId"),
			specializationId: "",
			descriptions: document.getElementById("txtHealthName").value,
			folderName: document.getElementById("txtFolderName").value,
			documentName: emrPatientdocument_name,
			emrSpecializationRecordId: emrPatientSpecializationRecordsId

		};
		callingAPI('clinicalBackground/saveAnnotation', postData).then((data) => {
			if (data.data.success === '1') {

				window.location.href = "/patients/patientFilesMedical"

			}
			else {
				window.location.href = "/patients/patientFilesMedical"
			}
		});
	}
	const saveAnnotationApi = () => {
		let flags = true;
		if (!annotationTitle) {
			toast.error('Title Required');
			document.getElementById('annotationTitle').focus();
			flags = false;
		} else if (!annotationDescription) {
			toast.error('Description Required');
			document.getElementById('annotationDescription').focus();
			flags = false;
		}
		if (flags === true) {
			const apiJson = {
				emrPatientRecordId: emrPatientRecordId,
				title: annotationTitle,
				description: annotationDescription,
				xAxis: strMouseX,
				yAxis: strMouseY,
				emrSpecializationRecordId: ""

			};
			callingAPI('clinicalBackground/saveInsideAnnotation', apiJson).then((data) => {
				if (data.data.success === '1') {
					toast.success('Annotation Saved Successfully');
					setShowPopup(false);
					setAnnotationTitle('')
					setAnnotationDescription('')
				}
			});
		}
	};

	const onChangeAnnotationDescription = (event) => {
		setAnnotationDescription(event.target.value);
	};

	const onChangeAnnotationTitle = (event) => {
		setAnnotationTitle(event.target.value);
	};
	useEffect(() => {
		if (location.state && Object.keys(location.state).length !== 0 && typeof location.state !== 'undefined') {
			console.log("emrPatientdocument_name", location.state);
			const { emrPatientdocument_name } = location.state;
			const { emrPatientRecordId } = location.state;
			const { emrPatientSpecializationRecordsId } = location.state;
			setemrPatientdocument_name(emrPatientdocument_name);
			setEmrPatientRecordId(emrPatientRecordId);
			setemrPatientSpecializationRecordsId(emrPatientSpecializationRecordsId)
		}
	}, []);

	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />

					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead" style={{ padding: '13px 10px 2px' }}>
												<span class="treatmentPlanName">HEALTH RECORD </span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b' }}
													onClick={(e) => saveFolderAnnotation(e)}
												>
													{/* <Link to="/patients/patientFilesMedical"> */}
													Done
													{/* </Link> */}

												</span>
											</div>

											<div
												class="viewportSub"
												//style={{overflow:"hidden"}}
												id="mouseChangeDiv"
												onClick={(e) => openPopupbox(e)}
											>

												<div
													id="annotationArea"
													className={showPopup ? "annotationAreaDiv-show" : "annotationAreaDiv-hide"}

												>
													<div className="annotationWrapper">
														<div
															className="annotationSubDiv">
															<p><input
																autocomplete="off"
																type="text"
																id="annotationTitle"
																className="annotationInput"
																placeholder="Title"
																value={annotationTitle}
																onChange={onChangeAnnotationTitle}

															/>
															</p>
															<p>
																<textarea
																	autocomplete="off"
																	className="annotationInput"
																	id="annotationDescription"
																	placeholder="Description"
																	value={annotationDescription}
																	onChange={onChangeAnnotationDescription}

																>
																</textarea></p>
															<div class="cropContentSec">
																<span class="cropContentSecBtn">
																	<a
																		id="cancelButton"
																		onClick={() => onChangeCancel()}
																		href="javascript:void(0);"
																		class="annoDes55"

																	>
																		Cancel
																	</a>
																	<a
																		id="saveButton"
																		onClick={() => onChangeSave()}
																		class="annoDes55"
																		href="javascript:void(0);"

																	>
																		Save
																	</a>
																</span>
															</div>
														</div>
													</div>
												</div>

												<div
													id="viewportImgId"
													className='viewport'>
													<PrismaZoom
														className="prismaZoom"
													>
														<img
															class="viewportImg"
															src={
																process.env.REACT_APP_S3_BUCKET_URL +

																process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
																process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH +
																'/' +
																emrPatientdocument_name
															}
														/>
														{/*  */}
														<div>

															{
																annotatedData.length > 0 &&

																annotatedData.map((annotated, i) => (

																	<div key={i}

																		// style={ annotated.x_axis+"%"> '40%' ? {left: "40%", left: annotated.x_axis+"%"} : {}}
																		style={{
																			left: annotated.x_axis + "%",
																			top: annotated.y_axis + "%",
																			position: 'absolute'
																		}}
																	>

																		<div class="annotationLocImg">
																			<p
																				class="annotation-marker-text"
																				title={annotated.title}
																			>{i + 1}
																			</p>
																			<img id={"blackId" + i} style={{ display: 'block' }} src={Location} />
																			<img id={"blueId" + i} style={{ display: 'none' }} src={LocationBlue} />
																		</div>
																	</div>

																))



															}

														</div>



														<div
															id="annotationAreaLoc"
															className={showPopup ? "annotationAreaDiv-show" : "annotationAreaDiv-hide"}

														>
															<div class="annotationLocImg">
																<p class="annotation-marker-text">{annotatedData.length + 1}</p>
																<img src={Location} />
															</div>
														</div>

														{/*  */}
														{/* <PopupboxContainer /> */}

													</PrismaZoom>

												</div>
											</div>

											{/*  */}
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<PatientFilesRight emrMedicalData={emrPatientRecordId} listupdated={annotationUpdated} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default PatientFilesMedicalView;
